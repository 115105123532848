import React, { useRef } from "react";
import { useEffect, useState } from "react";

import axios from 'axios'

// import { ReactComponent as Emoji } from './images/emoji.svg';

import {
  loadSaleEnabled,
  loadClaimEnabled,
  loadRefundEnabled,
  loadUserBalance,
  loadTotalInvested,
  loadTargetedRaise,
  loadRefUsdAmount,
  loadUserRefCount,
  loadRefPercentage,
  loadRefClaimed,
  loadTotalRefUsdAmount,
  buyToken,
  buyTokenRef,
  claimToken,
  claimRefReward,
  refundUsd,
  // loadUserBnbBalance,
  loadActiveVesting,
  loadTokensPerUsd,
  loadUserUsdBalance,
  loadUserMbpBalance,
  loadUsdAllowance,
  approveUsdSpending,
  loadMinMbpHolding,
  loadMaxBuyPerUser,
  loadUserDksBalance,
  loadMinDksHolding,
  stakeMBPToken,
  stakeDKSToken,
  unstakeMBPToken,
  unstakeDKSToken,
  loadUserMBPAmountStaked,
  loadUserDKSAmountStaked,
  loadUserMBP_StakeDaysCount,
  loadUserDKS_StakeDaysCount,
  loadMinDaysToUnstake,
  loadMinMBPStake,
  loadMinDKSStake
} from "./util/interact.js";

///////////////////////////////////////////////////////////////////////////////////////////////////
import {
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import {
  URI_AVAILABLE,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";

import {
  injected,
  // network,
  walletconnect,
  // walletlink,
  // ledger,
  // trezor,
  // frame,
  // fortmatic,
  // portis,
  // squarelink,
  // torus,
  // authereum
} from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";
///////////////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////////////
const connectorsByName = {
  Injected: injected,
  // Network: network,
  WalletConnect: walletconnect,
  // WalletLink: walletlink,
  // Ledger: ledger,
  // Trezor: trezor,
  // Frame: frame,
  // Fortmatic: fortmatic,
  // Portis: portis,
  // Squarelink: squarelink,
  // Torus: torus,
  // Authereum: authereum
};


function getErrorMessage(error) {

  if (error) {
    if (error instanceof NoEthereumProviderError) {
      return {
        status: "No BSC browser extension detected, install MetaMask on desktop or visit from a DApp browser on mobile.",
      };
    } else if (error instanceof UnsupportedChainIdError) {
      return {
        status: "You're connected to an unsupported network.",
      };
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      return {
        status: "Please authorize this website to access your account.",
      };
    } else {
      return {
        status: "WalletConnect connection rejected by user.",
      };
    }
  } else {
    // console.error(error);
    return {
      status: "Welcome to the IDO Page.",
    };
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////



const AppEngine = (props) => {

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  ///////////////////////////////////////////////////////////////////////////////////////////////////


  // Now USDT
  const BUSD_CONTRACT_ABI = require("./busd-contract-abi.json");
  const BUSD_CONTRACT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955";

  const MBP_CONTRACT_ABI = require("./mbp-contract-abi.json");
  const MBP_CONTRACT_ADDRESS = "0xaF2F53cc6cc0384aba52275b0f715851Fb5AFf94";
  // const MBP_CONTRACT_ABI = require("./test-token-contract-abi.json");//////////////////TEST
  // const MBP_CONTRACT_ADDRESS = "0x6De450C39f78540E2F5a2Db0aD15CEd7883752dA";/////TEST

  const DKS_CONTRACT_ABI = require("./dks-contract-abi.json");
  const DKS_CONTRACT_ADDRESS = "0x121235cfF4c59EEC80b14c1d38B44e7de3A18287";

  const ROUTER_CONTRACT_ABI = require("./router-contract-abi.json");
  const ROUTER_CONTRACT_ADDRESS = "0x204afc2b2e2c4d1a952E82872F2685e476F31aFF";
  // const ROUTER_CONTRACT_ABI = require("./test-router-contract-abi.json");/////////////TEST
  // const ROUTER_CONTRACT_ADDRESS = "0x6AcF63C772A3A49b97FeC6e3A58385125bCEAC0c";//TEST

  const STAKING_CONTRACT_ABI = require("./staking-contract-abi.json");
  const STAKING_CONTRACT_ADDRESS = "0x51a0d5B27f1E4748372b19D0a303974035A988D2";





  //state variables
  const [status, setStatus] = useState("Welcome to the IDO Page.");
  //for tracking smart contract update so the whole app refresh to broadcast the update
  //since we are not using socket that can listen to smart contract events
  const [smUpdate, setSmUpdate] = useState("");

  const [saleEnabled, setSaleEnabled] = useState(false);
  const [claimEnabled, setClaimEnabled] = useState(false);
  const [refundEnabled, setRefundEnabled] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [totalInvested, setTotalInvested] = useState(0);
  const [targetedRaise, setTargetedRaise] = useState(0);
  // const [userBnbBalance, setUserBnbBalance] = useState("--");
  const [userUsdBalance, setUserUsdBalance] = useState(0);
  const [userMbpBalance, setUserMbpBalance] = useState(0);
  const [userDksBalance, setUserDksBalance] = useState(0);

  const [investAmount, setInvestAmount] = useState(0);
  const [referrer, setReferrer] = useState("");

  const [userRefLink, setUserRefLink] = useState("");

  const [refUsdAmount, setRefUsdAmount] = useState(0);
  const [userRefCount, setUserRefCount] = useState(0);
  const [refPercentage, setRefPercentage] = useState(0);
  const [refClaimed, setRefClaimed] = useState(false);
  const [totalRefUsdAmount, setTotalRefUsdAmount] = useState(0);

  const [activeVesting, setActiveVesting] = useState("");

  const [tokensPerUsd, setTokensPerUsd] = useState(0);
  const [usdAllowance, setUsdAllowance] = useState(0);

  const [idoData, setIdoData] = useState([]);
  const [minMbpHolding, setMinMbpHolding] = useState(0);
  const [minDksHolding, setMinDksHolding] = useState(0);
  const [maxBuyPerUser, setMaxBuyPerUser] = useState(0);

  const [spinner, setSpinner] = useState(false);

  const [idoContractAddr] = useState(props.idoContractAddr);

  const [tokenToStake, setTokenToStake] = useState('MBP');
  const [stakeAmount, setStakeAmount] = useState(0);
  const [mbpAllowance, setMbpAllowance] = useState(0);
  const [dksAllowance, setDksAllowance] = useState(0);
  const [stakeState, setStakeState] = useState('STAKE');
  const [userMBPAmountStaked, setUserMBPAmountStaked] = useState(0);
  const [userDKSAmountStaked, setUserDKSAmountStaked] = useState(0);
  const [userMBPStakeDays, setUserMBPStakeDays] = useState(0);
  const [userDKSStakeDays, setUserDKSStakeDays] = useState(0);
  const [minDaysToUnstake, setMinDaysToUnstake] = useState(10);
  const [minMBPStake, setMinMBPStake] = useState(0);
  const [minDKSStake, setMinDKSStake] = useState(0);

  const [countdownString, setCountdownString] = useState("");
  const [countdownDays, setCountdownDays] = useState("");
  const [countdownHours, setCountdownHours] = useState("");
  const [countdownMinutes, setCountdownMinutes] = useState("");
  const [countdownSeconds, setCountdownSeconds] = useState("");



  // load DB Data // called only once

  useEffect(() => {

    // axios.get('http://localhost:8000/app-api/' + idoContractAddr)
    // axios.get('https://beta.mobipad.io/app-api/' + idoContractAddr)
    axios.get('https://mobipad.io/app-api/' + idoContractAddr)
      .then((res) => {
        setIdoData(res.data[0]);
      })
      .catch((err) => {
        console.log("An HTTP error occured");
      })

  }, [idoContractAddr]);



  //connection dependent calls in conditional useEffect
  useEffect(() => {

    if (library) {

      // Initialise the contract
      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */
      // Initialise Staking contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );

      async function fetchSaleEnabled() {
        const result = await loadSaleEnabled(ROUTER_CONTRACT, idoContractAddr);
        setSaleEnabled(result);
      }

      async function fetchClaimEnabled() {
        const result = await loadClaimEnabled(ROUTER_CONTRACT, idoContractAddr);
        setClaimEnabled(result);
      }

      async function fetchRefundEnabled() {
        const result = await loadRefundEnabled(ROUTER_CONTRACT, idoContractAddr);
        setRefundEnabled(result);
      }

      async function fetchTotalInvested() {
        const result = await loadTotalInvested(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalInvested(parseFloat(result));
        // set total invested to cloud if it has changed
        if (idoData.total_invested !== parseFloat(result) && parseFloat(result) !== 0) {
          // axios.get('http://localhost:8000/app-api/' + idoContractAddr + '/' + result);
          // axios.get('https://beta.mobipad.io/app-api/' + idoContractAddr + '/' + result);
          axios.get('https://mobipad.io/app-api/' + idoContractAddr + '/' + result);
        }
      }

      async function fetchTargetedRaise() {
        const result = await loadTargetedRaise(library, ROUTER_CONTRACT, idoContractAddr);
        setTargetedRaise(parseFloat(result));
      }

      async function fetchRefPercentage() {
        const result = await loadRefPercentage(ROUTER_CONTRACT, idoContractAddr);
        setRefPercentage(parseFloat(result));
      }

      async function fetchTotalRefUsdAmount() {
        const result = await loadTotalRefUsdAmount(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalRefUsdAmount(parseFloat(result));
      }

      async function fetchMinMbpHolding() {
        const result = await loadMinMbpHolding(library, ROUTER_CONTRACT, idoContractAddr);
        setMinMbpHolding(parseFloat(result));
      }

      async function fetchMinDksHolding() {
        const result = await loadMinDksHolding(library, ROUTER_CONTRACT, idoContractAddr);
        setMinDksHolding(parseFloat(result));
      }

      async function fetchMaxBuyPerUser() {
        const result = await loadMaxBuyPerUser(library, ROUTER_CONTRACT, idoContractAddr);
        setMaxBuyPerUser(parseFloat(result));
      }

      async function fetchActiveVesting() {
        const result = await loadActiveVesting(ROUTER_CONTRACT, idoContractAddr);
        setActiveVesting(result);
      }

      async function fetchTokensPerUsd() {
        const result = await loadTokensPerUsd(library, ROUTER_CONTRACT, idoContractAddr);
        setTokensPerUsd(parseFloat(result));
      }

      async function fetchMinDaysToUnstake() {
        const result = await loadMinDaysToUnstake(STAKING_CONTRACT);
        setMinDaysToUnstake(result);
      }

      async function fetchMinMBPStake() {
        const result = await loadMinMBPStake(library, STAKING_CONTRACT);
        setMinMBPStake(result);
      }

      async function fetchMinDKSStake() {
        const result = await loadMinDKSStake(library, STAKING_CONTRACT);
        setMinDKSStake(result);
      }



      fetchSaleEnabled();
      fetchClaimEnabled();
      fetchRefundEnabled();
      fetchTotalInvested();
      fetchTargetedRaise();
      fetchRefPercentage();
      fetchTotalRefUsdAmount();
      fetchActiveVesting();
      fetchTokensPerUsd();
      fetchMinMbpHolding();
      fetchMaxBuyPerUser();
      fetchMinDksHolding();

      fetchMinDaysToUnstake();
      fetchMinMBPStake();
      fetchMinDKSStake();
    }

    if (error) {
      const { status } = getErrorMessage(error);
      setStatus(status);
    }
    // if (active) {
    //   setStatus("Welcome to the IDO Page.");
    // }

    getReferrer();

  }, [library, chainId, smUpdate]);


  // wallet dependent calls in conditional useEffect
  useEffect(() => {

    if (library && account) {

      // Initialise the contract
      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */
      // Initialise BUSD contract
      var BUSD_CONTRACT = new library.eth.Contract(
        BUSD_CONTRACT_ABI,
        BUSD_CONTRACT_ADDRESS
      );
      // Initialise MBP contract
      var MBP_CONTRACT = new library.eth.Contract(
        MBP_CONTRACT_ABI,
        MBP_CONTRACT_ADDRESS
      );
      // Initialise DKS contract
      var DKS_CONTRACT = new library.eth.Contract(
        DKS_CONTRACT_ABI,
        DKS_CONTRACT_ADDRESS
      );
      // Initialise Staking contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );


      // async function fetchUserBnbBalance() {
      //   const result = await loadUserBnbBalance(library, account);
      //   setUserBnbBalance(result);
      // }

      async function fetchUserBalance() {
        const result = await loadUserBalance(library, ROUTER_CONTRACT, idoContractAddr, account);
        setUserBalance(parseFloat(result));
      }


      async function fetchRefUsdAmount() {
        const result = await loadRefUsdAmount(library, ROUTER_CONTRACT, idoContractAddr, account);
        setRefUsdAmount(parseFloat(result));
      }

      async function fetchUserRefCount() {
        const result = await loadUserRefCount(ROUTER_CONTRACT, idoContractAddr, account);
        setUserRefCount(parseFloat(result));
      }

      async function fetchRefClaimed() {
        const result = await loadRefClaimed(ROUTER_CONTRACT, idoContractAddr, account);
        setRefClaimed(result);
      }

      async function fetchUsdAllowance() {
        const result = await loadUsdAllowance(library, BUSD_CONTRACT, account, idoContractAddr);
        setUsdAllowance(parseFloat(result));
      }

      async function fetchMBPAllowance() {
        const result = await loadUsdAllowance(library, MBP_CONTRACT, account, STAKING_CONTRACT_ADDRESS);
        setMbpAllowance(parseFloat(result));
      }

      async function fetchDKSAllowance() {
        const result = await loadUsdAllowance(library, DKS_CONTRACT, account, STAKING_CONTRACT_ADDRESS);
        setDksAllowance(parseFloat(result));
      }

      async function fetchUserUsdBalance() {
        const result = await loadUserUsdBalance(library, BUSD_CONTRACT, account);
        setUserUsdBalance(parseFloat(result));
      }

      async function fetchUserMbpBalance() {
        const result = await loadUserMbpBalance(library, MBP_CONTRACT, account);
        setUserMbpBalance(parseFloat(result));
      }

      async function fetchUserDksBalance() {
        const result = await loadUserDksBalance(library, DKS_CONTRACT, account);
        setUserDksBalance(parseFloat(result));
      }

      async function fetchUserMBPAmountStaked() {
        const result = await loadUserMBPAmountStaked(library, STAKING_CONTRACT, account);
        setUserMBPAmountStaked(parseFloat(result));
      }

      async function fetchUserDKSAmountStaked() {
        const result = await loadUserDKSAmountStaked(library, STAKING_CONTRACT, account);
        setUserDKSAmountStaked(parseFloat(result));
      }

      async function fetchUserMBP_StakeDaysCount() {
        const result = await loadUserMBP_StakeDaysCount(STAKING_CONTRACT, account);
        setUserMBPStakeDays(parseFloat(result));
      }

      async function fetchUserDKS_StakeDaysCount() {
        const result = await loadUserDKS_StakeDaysCount(STAKING_CONTRACT, account);
        setUserDKSStakeDays(parseFloat(result));
      }



      fetchMBPAllowance();
      fetchDKSAllowance();
      fetchUserMBPAmountStaked();
      fetchUserDKSAmountStaked();
      fetchUserMBP_StakeDaysCount();
      fetchUserDKS_StakeDaysCount();

      fetchUserUsdBalance();
      fetchUserMbpBalance();
      fetchUserDksBalance();

      fetchUserBalance();
      fetchRefUsdAmount();
      fetchUserRefCount();
      fetchRefClaimed();
      // fetchUserBnbBalance();
      fetchUsdAllowance();

      processRefLink();

      if (error) {
        const { status } = getErrorMessage(error);
        setStatus(status);
      }
    }

  }, [library, account, chainId, smUpdate])//add the dependence in array


  // error dependent calls in conditional useEffect
  useEffect(() => {

    const { status } = getErrorMessage(error);
    setStatus(status);

  }, [error]);


  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // log the walletconnect URI
  React.useEffect(() => {
    const logURI = uri => {
      // console.log("WalletConnect URI", uri);
      console.log("WalletConnect URI Available");
    };
    walletconnect.on(URI_AVAILABLE, logURI);

    return () => {
      walletconnect.off(URI_AVAILABLE, logURI);
    };
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////


  //process countdown dependent in idoData
  useEffect(() => {
    processCountdown();
  }, [idoData]);


  //to get IDO address from URL
  // function getIdoAddress() {
  //   if (window.location.href.indexOf("/0x") > 0) {
  //     var completeUrl = window.location.href;
  //     var idoAddrStr = completeUrl.substring(completeUrl.indexOf("/0x") + 1);
  //     idoAddrStr = idoAddrStr.substring(0, 42);
  //     setIdoContractAddr(idoAddrStr);
  //   } else {
  //     setIdoContractAddr("");
  //   }
  // }
  //to get referral address from URL
  function processCountdown() {
    if (idoData.start) {

      // Update the count down every 1 second
      var x = setInterval(function () {

        // Set the date we're counting down to
        var countDownDate = new Date(idoData.start + "  UTC").getTime();
        // var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        setCountdownString("Sale Starts In:");

        if (distance <= 0) {
          countDownDate = new Date(idoData.end + "  UTC").getTime();
          now = new Date().getTime();
          distance = countDownDate - now;
          setCountdownString("Sale Ends In:");
        }

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"
        setCountdownDays(days);
        setCountdownHours(hours);
        setCountdownMinutes(minutes);
        setCountdownSeconds(seconds);

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);

          setCountdownDays(0);
          setCountdownHours(0);
          setCountdownMinutes(0);
          setCountdownSeconds(0);
        }
      }, 1000);
    } else {
      setCountdownDays(0);
      setCountdownHours(0);
      setCountdownMinutes(0);
      setCountdownSeconds(0);
    }
  }
  function getReferrer() {
    if (window.location.href.indexOf("?ref=") > 0) {
      var completeUrl = window.location.href;
      var refStr = completeUrl.substring(completeUrl.indexOf("?ref=") + 5);
      setReferrer(refStr);
    } else {
      setReferrer("");
    }
  }

  function processRefLink() {
    var userRefLinkStr;
    if (referrer.length > 0) {
      var completeUrl = window.location.href;
      userRefLinkStr = completeUrl.substring(0, completeUrl.indexOf("?ref=")) + "?ref=" + account;
      setUserRefLink(userRefLinkStr);
    } else {
      userRefLinkStr = window.location.href + "?ref=" + account;
      setUserRefLink(userRefLinkStr);
    }
  }

  const modalCloseButtonRef = useRef(null);

  const metamaskConnectPressed = async () => { //TODO: implement
    setActivatingConnector(connectorsByName["Injected"]);
    activate(connectorsByName["Injected"]);
    modalCloseButtonRef.current.click();
  };

  const walletconnectConnectPressed = async () => { //TODO: implement
    setActivatingConnector(connectorsByName["WalletConnect"]);
    activate(connectorsByName["WalletConnect"]);
    modalCloseButtonRef.current.click();
  };

  const disconnectWalletPressed = async () => { //TODO: implement
    if (active || error) {
      if (connector !== walletconnect) {//Logging out Metamask
        deactivate();
      } else {//Logging out WalletConnect
        connector.close();
      }
    }
  };


  ///////////////////////////////////////////////////////////////////////////////////////




  const onApproveUsdPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise BUSD contract
      var BUSD_CONTRACT = new library.eth.Contract(
        BUSD_CONTRACT_ABI,
        BUSD_CONTRACT_ADDRESS
      );
      const { status, smUpdate } = await approveUsdSpending(
        library, BUSD_CONTRACT, BUSD_CONTRACT_ADDRESS, idoContractAddr, account, investAmount
      );
      setStatus(status);
      setSmUpdate(smUpdate);
      setUsdAllowance(parseFloat(investAmount));
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };
  const onApproveTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      if (tokenToStake === "MBP") {
        // Initialise MBP contract
        var MBP_CONTRACT = new library.eth.Contract(
          MBP_CONTRACT_ABI,
          MBP_CONTRACT_ADDRESS
        );
        const { status, smUpdate } = await approveUsdSpending(
          library, MBP_CONTRACT, MBP_CONTRACT_ADDRESS, STAKING_CONTRACT_ADDRESS, account, stakeAmount
        );
        setStatus(status);
        setSmUpdate(smUpdate);
        setMbpAllowance(parseFloat(stakeAmount));
        setSpinner(false)
      }
      if (tokenToStake === "DKS") {
        // Initialise MBP contract
        var DKS_CONTRACT = new library.eth.Contract(
          DKS_CONTRACT_ABI,
          DKS_CONTRACT_ADDRESS
        );
        const { status, smUpdate } = await approveUsdSpending(
          library, DKS_CONTRACT, DKS_CONTRACT_ADDRESS, STAKING_CONTRACT_ADDRESS, account, stakeAmount
        );
        setStatus(status);
        setSmUpdate(smUpdate);
        setMbpAllowance(parseFloat(stakeAmount));
        setSpinner(false)
      }
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onBuyTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      if (!saleEnabled) {
        setStatus("Sale isn't currently active");
      } else {
        // Initialise the contract
        var ROUTER_CONTRACT = new library.eth.Contract(
          ROUTER_CONTRACT_ABI,
          ROUTER_CONTRACT_ADDRESS
        );
        /**
         * NOW library is the web3 instance
         * NOW ROUTER_CONTRACT is the contract instance
         */
        const { status, smUpdate } = await buyToken(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account, investAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setInvestAmount(0);
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };
  const onBuyTokenRefPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      if (!saleEnabled) {
        setStatus("Sale isn't currently active");
      } else {

        // Initialise the contract
        var ROUTER_CONTRACT = new library.eth.Contract(
          ROUTER_CONTRACT_ABI,
          ROUTER_CONTRACT_ADDRESS
        );
        /**
         * NOW library is the web3 instance
         * NOW ROUTER_CONTRACT is the contract instance
         */
        if (referrer.length > 0) {
          const { status, smUpdate } = await buyTokenRef(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account, referrer, investAmount);
          setStatus(status);
          setSmUpdate(smUpdate);
          setInvestAmount(0);
        } else {
          const { status, smUpdate } = await buyToken(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, account, investAmount);
          setStatus(status);
          setSmUpdate(smUpdate);
          setInvestAmount(0);
        }
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onStakeTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise the contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */

      if (tokenToStake === "MBP") {
        const { status, smUpdate } = await stakeMBPToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setStakeAmount(0);
      }
      if (tokenToStake === "DKS") {
        const { status, smUpdate } = await stakeDKSToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setStakeAmount(0);
      }

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onUnstakeTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise the contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */

      if (tokenToStake === "MBP") {
        const { status, smUpdate } = await unstakeMBPToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setStakeAmount(0);
      }
      if (tokenToStake === "DKS") {
        const { status, smUpdate } = await unstakeDKSToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setStakeAmount(0);
      }

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onClaimPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      if (!claimEnabled) {
        setStatus("Claim isn't currently active");
      } else {
        // Initialise the contract
        var ROUTER_CONTRACT = new library.eth.Contract(
          ROUTER_CONTRACT_ABI,
          ROUTER_CONTRACT_ADDRESS
        );
        /**
         * NOW library is the web3 instance
         * NOW ROUTER_CONTRACT is the contract instance
         */
        const { status, smUpdate } = await claimToken(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
        setStatus(status);
        setSmUpdate(smUpdate);
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };
  const onClaimRefRewardPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      if (!claimEnabled) {
        setStatus("Claim isn't currently active");
      } else {
        if (refClaimed === true) {
          setStatus("You have claimed yor referral reward");
        } else {
          if (refUsdAmount > 0) {
            // Initialise the contract
            var ROUTER_CONTRACT = new library.eth.Contract(
              ROUTER_CONTRACT_ABI,
              ROUTER_CONTRACT_ADDRESS
            );
            /**
             * NOW library is the web3 instance
             * NOW ROUTER_CONTRACT is the contract instance
             */
            const { status, smUpdate } = await claimRefReward(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
            setStatus(status);
            setSmUpdate(smUpdate);
          } else {
            setStatus("You don't have referral reward");
          }
        }
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };
  const onRefundPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise the contract
      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */
      const { status, smUpdate } = await refundUsd(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
      setStatus(status);
      setSmUpdate(smUpdate);
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };



  //the UI of our component
  return (

    <>




      <div className="main_wrapper">
        <header className="header">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="logo">
                <a href="">
                  <img src="images/logo.png" alt="" className="d-none d-md-block" />
                  <img
                    src="images/logo-mobile.png"
                    alt=""
                    className="d-block d-md-none"
                  />
                </a>
              </div>
              <div className="header-right d-flex align-items-center justify-content-center">
                <div
                  className="offcanvas offcanvas-start"
                  tabIndex={-1}
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <div className="offcanvas-header d-lg-none justify-content-between p-0">
                    <div className="offcanvas-header-logo">
                      <img src="images/logo-mobile.png" alt="" />
                    </div>
                    <div
                      className="header-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <i className="bi bi-x-lg" />
                    </div>
                  </div>
                  <div className="offcanvas-body">
                    <nav className="d-lg-none">
                      <ul className="navbar-nav navmenus">
                        <li className="nav-item">
                          <a href="https://mobipad.io/" className="nav-link">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="https://staking.mobipad.io" className="nav-link">
                            Staking
                          </a>
                        </li>
                      </ul>
                    </nav>
                    <div className="user-auth ms-auto">
                      <div className="d-flex align-items-center">
                        <ul className="d-lg-flex align-items-center">
                          <li>
                            <a href="https://mobipad.io/">
                              <img src="images/icon-home.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a href="" className="btn-primary text-uppercase btn-bsc">
                              <img src="images/icon-bsc.png" alt="" /> BSC
                            </a>
                          </li>


                          <li>
                            {(!active) && (
                              <button
                                className="btn-primary text-capitalize btn-connect"
                                style={{ marginBottom: 8 }}
                                data-bs-toggle="modal"
                                data-bs-target="#connectModal"
                              >
                                <img src="images/icon-connect.png" alt="" /> Connect
                              </button>
                            )}

                            {(active && account && connector) &&
                              <button
                                className="btn-primary text-capitalize btn-connect"
                                style={{ padding: 10, marginBottom: 8 }}
                              >
                                <img src={connector !== walletconnect ? "images/MetaMask_Fox.png" : "images/WalletConnect-icon.png"} alt=""></img>
                                {account.substring(0, 5) + "..." + account.substring(38)}
                              </button>
                            }
                            {(active) && (
                              <button
                                className="btn text-capitalize btn-connect"
                                id="logoutButton"
                                onClick={disconnectWalletPressed}
                                style={{ padding: 5, color: "white" }}
                              >
                                <i className="fas fa-sign-out-alt" />
                              </button>
                            )}
                          </li>


                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn d-lg-none bg-transparent border-0 p-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <span className="mobile-nav">
                  <img src="images/menu.svg" alt="" />
                </span>
              </button>
            </div>
          </div>
        </header>
        <img src="./images/shap-top-center-2.png" alt="" className="shap-headertop" />
        <img
          src="./images/mob-top-banner.png"
          alt=""
          className="mob-shap-headertop"
        />




        <main className="main">
          <div className="sbm-top-banner">
            <div className="container">
              <a href="https://mobipad.io/launchpad" className="backbtn">
                <i className="bi bi-arrow-left" /> BACK
              </a>
              <div className="row">
                <div className="col-md-6">
                  <div className="inner-Banner">
                    <h1>{idoData.name}</h1>
                    <p>
                      {idoData.f_detail}
                    </p>
                    <div className="cis-rangebox2">
                      <div className="rangebox-wrap2">
                        <span className="range-dsn" style={{ width: `${(totalInvested / targetedRaise) * 100}%` }}>
                          <b>{totalInvested}</b>
                        </span>
                      </div>
                      <span className="num_per">{targetedRaise}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="countdown-wrap size-xl">
                    <h4>{countdownString}</h4>
                    <div className="countdown">
                      <ul>
                        <li>
                          <span className="timeinfo">
                            <span className="days">{countdownDays}</span>
                            days
                          </span>
                        </li>
                        <li>
                          <span className="timeinfo">
                            <span className="hours">{countdownHours}</span>
                            Hours
                          </span>
                        </li>
                        <li>
                          <span className="timeinfo">
                            <span className="minutes">{countdownMinutes}</span>
                            Minutes
                          </span>
                        </li>
                        <li>
                          <span className="timeinfo">
                            <span className="seconds">{countdownSeconds}</span>
                            Seconds
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sbm-card-wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="cyan-box">
                    <div className="v-centered">
                      <ul className="list-leftrightcontent">
                        <li>
                          <span>Sale Type</span>
                          <span>IDO</span>
                        </li>
                        <li>
                          <span>Token Name</span>
                          <span>{idoData.name}</span>
                        </li>
                        <li>
                          <span>Token Symbol</span>
                          <span>{idoData.ticker}</span>
                        </li>
                        <li>
                          <span>Total Supply</span>
                          <span>{idoData.total_supply} {idoData.ticker}</span>
                        </li>
                        <li>
                          <span>Tokens For Presale</span>
                          <span>{idoData.tokensale_supply} {idoData.ticker}</span>
                        </li>
                        <li>
                          <span>Total Raise (All Rounds)</span>
                          <span>{idoData.total_raise} USDT</span>
                        </li>
                        <li>
                          <span>Platform Allocation</span>
                          <span>{targetedRaise} USDT</span>
                        </li>
                        <li>
                          <span>Presale Start Time (UTC) (Y-M-D)</span>
                          <span>{idoData.start}</span>
                        </li>
                        <li>
                          <span>Presale End Time (UTC) (Y-M-D)</span>
                          <span>{idoData.end}</span>
                        </li>
                        <li>
                          <span>Vesting Details</span>
                          <span>{idoData.vesting}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="cyan-box">
                    <div className="v-centered">
                      <h2>Stake Requirement</h2>
                      <ul className="list-leftrightcontent">
                        <li>
                          <span>Minimum Days to Stake for this IDO {idoData.min_stake_days} Days</span>
                        </li>
                        <li>
                          <span>Minimum MBP Stake {minMBPStake} MBP</span>
                        </li>
                        <li>
                          <span>Minimum Days before unstake {minDaysToUnstake} Days</span>
                        </li>
                        <li>
                          <span className="text-clr-1">
                            * You MUST have staked minimum of {minMbpHolding} MBP for {idoData.min_stake_days} Days before
                            you can buy in the IDO
                          </span>
                        </li>
                        <li>
                          <span className="text-clr-1">
                            * You MUST still be staking before you can claim the IDO
                          </span>
                        </li>
                        <li>
                          <span className="text-clr-1">
                            * You can only unstake tokens {minDaysToUnstake} Days after staking them
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="cyan-box h-auto mb-4">
                    <div className="v-centered">
                      <a href="https://staking.mobipad.io" className="float-end">
                        <svg
                          width={35}
                          height={36}
                          viewBox="0 0 35 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="17.5" cy="17.5781" r="17.5" fill="#00C3C8" />
                          <path
                            d="M14.9062 19.1355L23.9993 10.0425M23.9993 10.0425H19.1853M23.9993 10.0425V14.8565"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <path
                            d="M15.9768 12.1819H11.6279C11.0756 12.1819 10.6279 12.6297 10.6279 13.1819V21.8797C10.6279 22.432 11.0756 22.8797 11.6279 22.8797H21.3954C21.9477 22.8797 22.3954 22.432 22.3954 21.8797V18.6006"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </a>
                      <h2>Stake</h2>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <select
                            className="form-select"
                            value={stakeState}
                            onChange={(e) => setStakeState(e.target.value)}
                          >
                            <option value="STAKE">Stake</option>
                            <option value="UNSTAKE">Unstake</option>
                          </select>
                        </div>

                        <div className="col-md-6 mb-3">
                          <select
                            className="form-select"
                            value={tokenToStake}
                            onChange={(e) => setTokenToStake(e.target.value)}
                          >
                            <option value="MBP">MBP</option>
                            {/* <option value="DKS">DKS</option> */}
                          </select>
                        </div>

                        <div className="col-md-12 mb-3">
                          <div className="number-input">
                            <input
                              id="number"
                              type="number"
                              className="form-control"
                              placeholder="Amount"
                              onChange={(e) => setStakeAmount(e.target.value)}
                              value={stakeAmount}
                            />

                            <div className="buttons">
                              <div className="increment">
                                <svg
                                  width={7}
                                  height={5}
                                  viewBox="0 0 7 5"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.15623 0.835453C3.34905 0.652879 3.65095 0.652879 3.84377 0.835453L6.4856 3.33688C6.81386 3.64769 6.59389 4.19995 6.14183 4.19995H0.858171C0.406109 4.19995 0.186138 3.64769 0.514398 3.33688L3.15623 0.835453Z"
                                    fill="#505050"
                                  />
                                </svg>
                              </div>
                              <div className="decrement">
                                <svg
                                  width={7}
                                  height={5}
                                  viewBox="0 0 7 5"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.84377 4.04034C3.65095 4.22291 3.34905 4.22291 3.15623 4.04034L0.514399 1.53891C0.186139 1.2281 0.406111 0.675844 0.858173 0.675844L6.14183 0.675845C6.59389 0.675845 6.81386 1.2281 6.4856 1.53891L3.84377 4.04034Z"
                                    fill="#505050"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>

                        {(spinner) &&
                          <div className="col-md-12 mb-3">
                            <div>
                              <button href="" className="btn w-100 btn-info">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                &nbsp;
                                Please Wait...
                              </button>
                            </div>
                          </div>
                        }

                        {(stakeState === "STAKE" && tokenToStake === "MBP" && stakeAmount > userMbpBalance) &&
                          <>
                            <div className="col-md-12 mb-3 text-center">
                              <small className="text-danger">
                                *** Insufficient MBP Balance
                              </small>
                            </div>
                          </>
                        }
                        {(stakeState === "STAKE" && tokenToStake === "DKS" && stakeAmount > userDksBalance) &&
                          <>
                            <div className="col-md-12 mb-3 text-center">
                              <small className="text-danger">
                                *** Insufficient DKS Balance
                              </small>
                            </div>
                          </>
                        }

                        <div className="col-md-12 mb-3">
                          <div className="row">


                            {(active && account && !spinner) && (
                              <>
                                {(stakeState === "STAKE" && tokenToStake === "MBP" && stakeAmount > 0 && mbpAllowance < stakeAmount) &&
                                  <div className="col">
                                    <button
                                      className="btn w-100 btn-info"
                                      id="approveMbp"
                                      onClick={onApproveTokenPressed}
                                      disabled={spinner}
                                    >
                                      Approve MBP
                                    </button>
                                  </div>
                                }
                                {(stakeState === "STAKE" && tokenToStake === "DKS" && stakeAmount > 0 && dksAllowance < stakeAmount) &&
                                  <div className="col">
                                    <button
                                      className="btn w-100 btn-info"
                                      id="approveDks"
                                      onClick={onApproveTokenPressed}
                                      disabled={spinner}
                                    >
                                      Approve DKS
                                    </button>
                                  </div>
                                }
                              </>
                            )}




                            {(stakeState === "STAKE" && active && account && !spinner) && (<>
                              <div className="col">
                                <button
                                  className="btn btn-info w-100"
                                  id="stakeTokensButton"
                                  onClick={onStakeTokenPressed}
                                  disabled={spinner}
                                >
                                  STAKE
                                </button>
                              </div>
                            </>)}

                            {(stakeState === "UNSTAKE" && active && account && !spinner) && (<>
                              <div className="col">
                                <button
                                  className="btn btn-info w-100"
                                  id="unstakeTokensButton"
                                  onClick={onUnstakeTokenPressed}
                                  disabled={spinner}
                                >
                                  UNSTAKE
                                </button>
                              </div>
                            </>)}


                          </div>
                        </div>

                        <hr />


                        <div className="col-md-12 text-center">
                          <p className="checkinfo text-info">
                            You Staked {userMBPAmountStaked} MBP for {userMBPStakeDays} Days
                          </p>
                        </div>
                        {/* {userDKSAmountStaked > 0 &&
                          <>
                            <div className="col-md-12 text-center">
                              <p className="checkinfo text-info">
                                You Staked {userDKSAmountStaked} DKS for {userDKSStakeDays} Days
                              </p>
                            </div>
                          </>
                        } */}

                        <div className="col-md-12">

                          {
                            (userMBPAmountStaked >= minMbpHolding || userDKSAmountStaked >= minDksHolding) &&
                              (userMBPStakeDays >= idoData.min_stake_days || userDKSStakeDays >= idoData.min_stake_days) ?
                              <>
                                <p className="checkinfo">
                                  <svg
                                    width={16}
                                    height={17}
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle cx={8} cy="8.57812" r={8} fill="green" />
                                    <path
                                      d="M3 8.07812L7.5 12.0781L13 5.57812"
                                      stroke="black"
                                    />
                                  </svg>
                                  {" "}
                                  You are eligible to invest in this IDO
                                </p>
                              </>
                              :
                              <>
                                <p className="checkinfo">
                                  <svg
                                    width={16}
                                    height={17}
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle cx={8} cy="8.57812" r={8} fill="red" />
                                    <path
                                      d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"
                                      stroke="black"
                                    />
                                  </svg>
                                  {" "}
                                  You are not eligible to invest in this IDO
                                </p>
                              </>
                          }


                        </div>

                      </div>
                    </div>
                  </div>



                  <div className="cyan-box h-auto">
                    <div className="v-centered">
                      <div className="cyan-box-dsn-1">
                        <h3 className="mb-5">Claim Section</h3>




                        {(spinner) &&
                          <div className="col-md-12 mb-3">
                            <div>
                              <button className="btn w-100 btn-info">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                &nbsp;
                                Please Wait...
                              </button>
                            </div>
                          </div>
                        }


                        {(claimEnabled && !spinner) &&
                          <div className="col-md-12 mb-3">
                            <div>
                              <button
                                className="btn w-100 btn-success"
                                id="claim"
                                onClick={onClaimPressed}
                                disabled={spinner}
                              >
                                Claim {idoData.ticker} Tokens
                                {activeVesting === "vesting1" ? " - Vesting 1" :
                                  (activeVesting === "vesting2" ? " - Vesting 2" :
                                    (activeVesting === "vesting3" ? " - Vesting 3" :
                                      (activeVesting === "vesting4" ? " - Vesting 4" : " - No Active Vesting")))
                                }
                              </button>
                            </div>
                          </div>
                        }



                        {(claimEnabled && !refClaimed && refUsdAmount > 0 && !spinner) &&
                          <div className="col-md-12 mb-3">
                            <div>
                              <button
                                className="btn w-100 btn-info"
                                id="claimReferralReward"
                                onClick={onClaimRefRewardPressed}
                                disabled={spinner}
                              >
                                Claim Referral Reward
                              </button>
                            </div>
                          </div>
                        }


                        {(refundEnabled && !spinner) &&
                          <div className="col-md-12 mb-3">
                            <div>
                              <button
                                className="btn w-100 btn-info"
                                id="refundUsd"
                                onClick={onRefundPressed}
                                disabled={spinner}
                              >
                                Refund Investment
                              </button>
                            </div>
                          </div>
                        }



                        {/* <hr />
                        <p className="text-clr-1">Status:</p>
                        <p>Welcome to the IDO Page.</p> */}

                      </div>
                    </div>
                  </div>



                </div>




                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="cyan-box">
                    <div className="v-centered">


                      <h4 className="mb-4">Contribute</h4>
                      {/* <p className="checkinfo">Contribute</p> */}


                      <div className="number-input mb-1">
                        <input
                          id="number"
                          type="number"
                          className="form-control"
                          placeholder="Enter USDT Amount"
                          onChange={(e) => setInvestAmount(e.target.value)}
                          value={investAmount}
                        />

                        <div className="buttons">
                          <div className="increment">
                            <svg
                              width={7}
                              height={5}
                              viewBox="0 0 7 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.15623 0.835453C3.34905 0.652879 3.65095 0.652879 3.84377 0.835453L6.4856 3.33688C6.81386 3.64769 6.59389 4.19995 6.14183 4.19995H0.858171C0.406109 4.19995 0.186138 3.64769 0.514398 3.33688L3.15623 0.835453Z"
                                fill="#505050"
                              />
                            </svg>
                          </div>
                          <div className="decrement">
                            <svg
                              width={7}
                              height={5}
                              viewBox="0 0 7 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.84377 4.04034C3.65095 4.22291 3.34905 4.22291 3.15623 4.04034L0.514399 1.53891C0.186139 1.2281 0.406111 0.675844 0.858173 0.675844L6.14183 0.675845C6.59389 0.675845 6.81386 1.2281 6.4856 1.53891L3.84377 4.04034Z"
                                fill="#505050"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>



                      {investAmount > userUsdBalance &&
                        <>
                          <div className="col-md-12 my-3 text-center">
                            <small className="text-danger">
                              *** Insufficient USDT Balance
                            </small>
                          </div>
                        </>
                      }


                      {(spinner) &&
                        <div className="col-md-12 my-3">
                          <div>
                            <button href="" className="btn w-100 btn-info">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              &nbsp;
                              Please Wait...
                            </button>
                          </div>
                        </div>
                      }


                      <div className="col-md-12 mb-3 mt-4">
                        <div className="row">


                          {(active && account && !spinner) && (
                            <>
                              {(investAmount > 0 && usdAllowance < investAmount) &&
                                <div className="col">
                                  <button
                                    className="btn w-100 btn-info"
                                    id="approveUsd"
                                    onClick={onApproveUsdPressed}
                                    disabled={spinner}
                                  >
                                    Approve USDT
                                  </button>
                                </div>
                              }
                            </>
                          )}




                          {(active && account && !spinner) && (<>
                            <div className="col">
                              {(referrer.length > 0) ?
                                <button
                                  className="btn btn-info w-100"
                                  id="buyReferral"
                                  onClick={onBuyTokenRefPressed}
                                  disabled={spinner}
                                >
                                  BUY
                                </button>
                                :
                                <button
                                  className="btn btn-info w-100"
                                  id="buy"
                                  onClick={onBuyTokenPressed}
                                  disabled={spinner}
                                >
                                  BUY
                                </button>
                              }
                            </div>
                          </>)}



                        </div>
                      </div>





                      <ul className="list-leftrightcontent">
                        <li>
                          <span>Token Price</span>
                          <span>{1 / tokensPerUsd} USDT</span>
                        </li>
                        <li>
                          <span>Rate per 1 USDT</span>
                          <span>{tokensPerUsd} {idoData.ticker} / USDT</span>
                        </li>
                        <li>
                          <span>Maximum Buy</span>
                          <span>{maxBuyPerUser} USDT</span>
                        </li>
                        <li>
                          <span>Referral Percentage</span>
                          <span>{refPercentage} %</span>
                        </li>
                        <li>
                          <span>Current Total Referral Reward</span>
                          <span>{totalRefUsdAmount} USDT</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>



                <div className="col-lg-4 mb-4">
                  <div className="cyan-box">
                    <div className="v-centered">

                      <div className="text-center mb-3">
                        {/* <p className="text-clr-1">Status:</p> */}
                        <p>{status}</p>
                      </div>

                      <div className="cb-inner-borderbox">
                        <p className="text-clr-1">Your Investment:</p>
                        <p>{userBalance} USDT</p>
                        <p className="text-clr-1">You Will Get:</p>
                        <p>{userBalance * tokensPerUsd} {idoData.ticker}</p>
                      </div>
                      <div className="text-center">
                        <p className="text-clr-1">Your Referral Reward:</p>
                        <p>{refUsdAmount} USDT</p>
                        <p className="text-clr-1">You have referred {userRefCount} investors</p>
                        <hr className="my-4" />




                        {(userRefLink && userBalance > 0) ?
                          <>
                            <p className="text-clr-1">Your Referral Link:</p>
                            <a
                              href={userRefLink}
                              style={{ overflowWrap: "break-word", color: "grey" }}
                            >
                              {userRefLink}
                            </a>
                          </>
                          :
                          <>
                            <p className="text-clr-1">Your Referral Link:</p>
                            <p style={{ overflowWrap: "break-word" }}>
                              You must buy in the IDO before you can get a referral link.
                            </p>
                          </>
                        }


                        {referrer.length > 0 && (
                          <>
                            <p className="text-clr-1">You were referred by:</p>
                            <p style={{ overflowWrap: "break-word" }}>
                              {referrer}
                            </p>
                          </>
                        )}





                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>
          </div>
        </main>
      </div>



      <>
        {/* Modal */}
        {/* connect modal  */}
        <div
          className="modal fade wallet-modal "
          id="connectModal"
          tabIndex={-1}
          aria-labelledby="connectModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-inner">
                <div className="modal-content">
                  <button
                    type="button"
                    className="modal-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={modalCloseButtonRef}
                  >
                    <img src="images/close.png" alt="" />
                  </button>
                  <div className="modal-body">
                    <div className="connect-wallet-modal">
                      <h3 className="connect-walletText text-center text-uppercase">
                        Connect wallet
                      </h3>
                    </div>
                    <div className="wallet-inner text-center">
                      <p>Choose a provider to connect wallet </p>
                      <div className="wallet-btns">
                        <div className="metamask">
                          <p
                            className="metamask-btn"
                            id="MetaMaskButton"
                            onClick={metamaskConnectPressed}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img src="images/MetaMask_Fox.png" alt="" />
                            MetaMask
                          </p>

                        </div>
                        <div className="metamask mt-20">
                          <p
                            className="metamask-btn"
                            id="WalletConnect"
                            onClick={walletconnectConnectPressed}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img src="images/WalletConnect-icon.png" alt="" />{" "}
                            WalletConnect
                          </p>
                        </div>

                        {/* <div className="metamask mt-20">
                          <a href="#" className="metamask-btn">
                            {" "}
                            <img src="images/Coinbase-logo-square.png" alt="" />{" "}
                            Coinbase
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>




















    </>

  );
};

export default AppEngine;
